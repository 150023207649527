<template>
  <v-row>
    <v-col cols="12" class="ProfessionalItem--Tile my-1">
      <v-row align="center">
        <v-col cols="2"> Professionel </v-col>
        <v-col cols="3">
          <v-icon color="#37A3C6">mdi-briefcase-outline</v-icon>
          <span class="subtitle-1"> {{ occupation }}</span>
        </v-col>
        <v-col cols="4">
          <v-icon color="#37A3C6">mdi-email-outline</v-icon>
          <span class="subtitle-1"> {{ professional.email }}</span>
        </v-col>
        <v-col cols="1">
          <v-row>
            <v-col cols="12" class="pa-0"> Abonnement </v-col>
            <v-col cols="12" class="pa-0">
              <span class="subtitle-1 green--text">
                {{ subscriptionsTotalPrice }} € / mois
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2">
          <v-row justify="end">
            <v-btn
              color="#37A3C6"
              class="white--text mr-3"
              :to="`/professionals/${professional.id}`"
            >
              voir plus
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import { find } from "lodash/core";
import Occupation from "../enums/Occupation.enum";

export default {
  name: "ProfessionalItem",
  props: {
    professional: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      prices: (state) => state.subscriptions.prices,
    }),
    subscriptionsTotalPrice() {
      return this.professional.subscription &&
        this.professional.subscription.items.length > 0 &&
        this.prices.length > 0
        ? this.professional.subscription.items.reduce(
            (accu, item) =>
              accu +
              find(this.prices, (price) => price.id === item.price).unit_amount,
            0
          ) / 100
        : 0;
    },
    occupation() {
      switch (this.professional.enterprise?.occupation) {
        case Occupation.MANDATAIRE_JUDICIAIRE:
          return "Mandataire judiciaire";
        case Occupation.AVOCAT:
          return "Avocat";
        case Occupation.ADMINISTRATEUR_JUDICIARE:
          return "Administrateur judiciaire";
        case Occupation.EXPERT_COMPTABLE:
          return "Expert comptable";
        default:
          return "Profession non renseignée";
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../styles/Professionals/professional-item.less";
</style>