<template>
  <v-row>
    <v-col cols="12">
      <v-row justify="center">
        <v-progress-circular
          :rotate="-90"
          :size="150"
          :width="25"
          :value="value"
          :color="color"
        >
          <span class="black--text">{{ value }} %</span>
        </v-progress-circular>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row justify="center">
        {{ title }}
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "InfosCircular",
  props: {
    color: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
  },
};
</script>
