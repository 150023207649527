<template>
  <div id="professionals">
    <v-container fluid>
      <v-row>
        <v-col>
          <v-row align="center">
            <h2 class="pa-0 pl-4">Professionnels</h2>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="ml-0">
        <v-col cols="12">
          <ProfessionalItem
            :key="prof.id"
            v-for="prof in paginatedProfessionals.data"
            :professional="prof"
          />
        </v-col>
        <v-col cols="12">
          <v-row justify="end">
            <v-pagination
              v-model="page"
              :length="paginatedProfessionals.total_pages"
            ></v-pagination>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ProfessionalItem from "../components/ProfessionalItem.vue";
import { paginator } from "@/utils/functions";

export default {
  name: "Professionals",
  components: {
    ProfessionalItem,
  },
  data() {
    return {
      page: 1,
    };
  },
  created() {
    this.$store.dispatch("professionals/fetchProfessionals");
    this.$store.dispatch("subscriptions/fetchPrices");
  },
  computed: {
    ...mapState({
      professionals: (state) => state.professionals.professionals,
    }),
    paginatedProfessionals() {
      return paginator(this.professionals, this.page, 10);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../styles/Professionals/professionals.less";
</style>
