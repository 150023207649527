<template>
  <div id="dashboard">
    <v-container fluid>
      <v-row>
        <v-col>
          <v-row align="center">
            <h2 class="pa-0 pl-4">Tableau de bord</h2>
            <v-menu
              ref="menuTilesDate"
              v-model="menuTilesDate"
              :close-on-content-click="false"
              :return-value.sync="tilesDate"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="tilesDate"
                  label="Filtrer par date"
                  class="Dashboard--DateSelector ml-3"
                  prepend-icon="mdi-calendar"
                  hide-details
                  clearable
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="
                    () => $store.dispatch('dashboard/fetchTilesData')
                  "
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="tilesDate"
                type="month"
                color="#37A3C6"
                :max="maxDateAllowed"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="#37A3C6" @click="menuTilesDate = false">
                  Annuler
                </v-btn>
                <v-btn text color="#37A3C6" @click="saveDate"> OK </v-btn>
              </v-date-picker>
            </v-menu>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="Dashboard--Tile ma-3">
          <v-row>
            <v-col cols="12">
              <v-icon class="pt-5 pl-5" size="45" color="#37A3C6">
                mdi-briefcase-search-outline
              </v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" offset="1" class="Dashboard--TileTitle">
              Diagnostics réalisés
            </v-col>
            <v-col cols="6">
              <v-row justify="center" align="center" class="fill-height">
                {{ tilesData.nbDiagnostic }}
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="Dashboard--Tile ma-3">
          <v-row>
            <v-col cols="12">
              <v-icon class="pt-5 pl-5" size="45" color="#00A389">
                mdi-chart-timeline-variant-shimmer
              </v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" offset="1" class="Dashboard--TileTitle">
              BESE
            </v-col>
            <v-col cols="6">
              <v-row justify="center" align="center" class="fill-height">
                {{ tilesData.nbBese }}
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="Dashboard--Tile ma-3">
          <v-row>
            <v-col cols="12">
              <v-icon class="pt-5 pl-5" size="45" color="#FFB9CD">
                mdi-file-plus-outline
              </v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" offset="1" class="Dashboard--TileTitle">
              Abonnements
            </v-col>
            <v-col cols="6">
              <v-row justify="center" align="center" class="fill-height">
                {{ tilesData.nbSubscription }}
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="Dashboard--Tile ma-3">
          <v-row>
            <v-col cols="12">
              <v-icon class="pt-5 pl-5" size="45" color="#FF5B5B">
                mdi-account-group-outline
              </v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" offset="1" class="Dashboard--TileTitle">
              Partenariats
            </v-col>
            <v-col cols="6">
              <v-row justify="center" align="center" class="fill-height">
                {{ tilesData.nbPartnership }}
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="Dashboard--Tile mx-3">
          <v-row>
            <v-col cols="12">
              <h3>Informations générales</h3>
            </v-col>
            <v-col>
              <InfosCircular
                title="Téléchargent une consultation"
                :value="tilesData.procedureFileDownloadedPercentage"
                color="#FFB9CD"
              />
            </v-col>
            <v-col>
              <InfosCircular
                title="Achètent le BESE"
                :value="tilesData.buyingBesePercentage"
                color="#00A389"
              />
            </v-col>
            <v-col>
              <InfosCircular
                title="Achètent la procédure"
                :value="tilesData.buyingProcedurePercentage"
                color="#00A389"
              />
            </v-col>
            <v-col>
              <InfosCircular
                title="Professionels partenaires"
                :value="tilesData.partnershipPercentage"
                color="#B9EEFF"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import InfosCircular from "../components/InfosCircular.vue";

export default {
  name: "Dashboard",
  components: {
    InfosCircular,
  },
  data() {
    return {
      items: [],
      tilesDate: undefined,
      menuTilesDate: undefined,
    };
  },
  created() {
    this.$store.dispatch("dashboard/fetchTilesData");
  },
  computed: {
    ...mapGetters(["getImage"]),
    ...mapState({
      tilesData: (state) => state.dashboard.tilesData,
    }),
    maxDateAllowed() {
      const dateTimeNow = new Date();
      return `${dateTimeNow.getFullYear()}-${dateTimeNow.getMonth() + 1}`;
    },
  },
  methods: {
    saveDate() {
      this.$refs.menuTilesDate.save(this.tilesDate);
      this.$store.dispatch("dashboard/fetchTilesData", this.tilesDate + "-01");
    },
  },
};
</script>

<style lang="less" scoped>
@import "../styles/Dashboard/dashboard.less";
</style>
